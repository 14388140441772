import type { ZudokuConfig, SlotletComponentProps } from "zudoku";
import { sidebar } from "./sidebar";
import { GettingStartedCallout } from "./src/GettingStartedCallout";
import { LandingPage } from "./src/LandingPage";
import { PricingPage } from "./src/PricingPage";
import { Catalog } from "./src/Catalog";
import apis from "./apis";
import { Link } from "zudoku/components";
import { Breadcrumbs } from "./src/Breadcrumbs";

const config: ZudokuConfig = {
  page: {
    pageTitle: "",
    logo: {
      src: {
        light: "/DarkMatterLogoLight.svg",
        dark: "/DarkMatterLogoDark.svg",
      },
      width: "200px",
    },
    banner: {
      message: (
        <div className="text-center">
          <a
            href="https://dmatter.com/horizon"
            className="hover:underline"
            target="_blank"
          >
            Dark Matter’s 2025 User Conference: Horizon <b>April 22–24</b>
          </a>
        </div>
      ),
      dismissible: true,
      color: "note",
    },
  },
  metadata: {
    favicon:
      "https://dmatter.com/wp-content/uploads/cropped-dm-icon-1024-4-32x32.png",
    title: "%s | Dark Matter Technologies",
  },
  theme: {
    light: {
      primary: "346 71.4% 41.2%",
    },
    dark: {
      background: "0 0% 6.67%",
      primary: "346 65.2% 49.6%",
      primaryForeground: "360 100% 100%",
      mutedForeground: "215 9.15% 66.66%",
    },
  },
  customPages: [
    { path: "/home", element: <LandingPage /> },
    {
      path: "/catalog",
      render: (props: SlotletComponentProps) => <Catalog {...props} />,
    },
    { path: "/pricing", element: <PricingPage /> },
  ],
  UNSAFE_slotlets: {
    "api-keys-list-page": <GettingStartedCallout />,
    "top-navigation-after": (props) => <Breadcrumbs {...props} />,
    "zudoku-before-content": ({
      location,
      ...props
    }: SlotletComponentProps) => {
      if (!location.pathname.startsWith("/api")) {
        return null;
      }
      return (
        <div className="pt-[--padding-content-top]">
          <div className="w-full max-w-screen-2xl mx-auto p-6 bg-[#F9FAFC]/50 rounded-lg border grid grid-cols-2">
            <div>
              <h1 className="font-semibold text-2xl">
                API for Financial Technology
              </h1>
              <p>
                Dark Matter Technologies provides predictive AI and automation
                tools to help streamline lending decisions.
              </p>
            </div>
            <div className="flex items-center justify-end">
              <button
                className="border rounded-full bg-white p-1 pl-4 pr-2 text-sm flex items-center gap-2"
                onClick={() => alert("No other version available")}
              >
                Version 3.2
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-chevrons-up-down"
                >
                  <path d="m7 15 5 5 5-5" />
                  <path d="m7 9 5-5 5 5" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      );
    },
  },
  authentication: {
    type: "auth0",
    domain: "dev-ypl8tuipvuuy14ui.us.auth0.com",
    clientId: "DFi18LZlfqs0V2gxVPpW3SuqMgDKC028",
    audience: "https://darkmatter-poc.pages.dev/",
    // redirectToAfterSignIn: "/settings/api-keys",
    // redirectToAfterSignUp: "/settings/api-keys",
  },
  search: {
    type: "inkeep",
    apiKey: "a726486bd9048d2fbd1789040cdbef04f0cf597079a6ff24",
    integrationId: "cm250v0ga009cu50im02bohso",
    organizationId: "org_uR0BlvJ8tOM8NODH",
    primaryBrandColor: "#183C57",
    organizationDisplayName: "Darkmatter",
  },
  topNavigation: [
    { label: "Home", id: "home" },
    { label: "API Library", id: "catalog" },
    {
      label: "Documentation",
      id: "documentation",
      default: "documentation/welcome",
    },
    // { label: "API Reference", id: "api" },
    // { label: "Pricing", id: "pricing" },
  ],
  sidebar: { documentation: sidebar },
  redirects: [{ from: "/", to: "/home" }],
  apis: apis.map((api) => ({
    type: "url",
    input: api.input,
    navigationId: api.navigationId,
    skipPreload: true,
  })),
  docs: {
    files: "/pages/**/*.mdx",
  },
};

export default config;
