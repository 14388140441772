import apis from "../apis";
import type { ExposedComponentProps } from "zudoku";

export const Breadcrumbs = ({
  location,
  searchParams,
}: ExposedComponentProps) => {
  const api = apis.find((api) => "/" + api.navigationId === location.pathname);
  const category = searchParams.get("category");

  console.log(category);

  if (!api) return;

  return (
    <div className="flex gap-2 px-12 py-6 text-sm [&_a]:text-primary font-medium -mb-12">
      <a href={"/catalog"}>API Library</a>
      <span>/</span>
      {category && (
        <>
          <a href={`/catalog?category=${category.replaceAll(" ", "+")}`}>
            {category}
          </a>
          <span>/</span>
        </>
      )}
      <a href={location.pathname}>{api.title}</a>
    </div>
  );
};
