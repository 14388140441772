import type { Sidebar } from "zudoku";

type SidebarEntry = Sidebar[keyof Sidebar];

export const sidebar: SidebarEntry = [
  {
    type: "category",
    label: "Getting Started",
    collapsed: false,
    collapsible: false,
    items: ["welcome"],
  },
  {
    type: "category",
    label: "General Information",
    collapsed: false,
    collapsible: false,
    items: ["choosing-los", "empower-los", "nova-los"],
  },
];
