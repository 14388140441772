import { Head, Link } from "zudoku/components";
import apis from "../apis";
import type { ExposedComponentProps } from "zudoku";

export const Catalog = ({
  searchParams,
  setSearchParams,
}: ExposedComponentProps) => {
  const category = searchParams.get("category");

  return (
    <section className="pt-[--padding-content-top] pb-[--padding-content-bottom]">
      <Head>
        <title>API Library</title>
      </Head>
      <div className="grid grid-cols-12 gap-12">
        <div className="flex flex-col gap-4 col-span-3 px-4 not-prose sticky top-48">
          <div className="flex justify-between">
            <span className="font-medium text-sm">Products</span>
            {category && (
              <button
                type="button"
                className="text-end text-sm mr-8 text-foreground/60 hover:text-foreground"
                onClick={() => setSearchParams({})}
              >
                Clear
              </button>
            )}
          </div>
          <ul className="space-y-1 [&>li]:py-2">
            {Array.from(new Set(apis.flatMap((api) => api.tags)))
              .map((tag) => ({
                tag,
                count: apis.filter((api) => api.tags.includes(tag)).length,
              }))
              .map(({ tag, count }) => (
                <li
                  key={tag}
                  className={`flex px-4 rounded-lg -translate-x-4 justify-between text-sm cursor-pointer hover:text-primary transition ${
                    tag === category ? "font-medium bg-border/30 rounded" : ""
                  }`}
                  onClick={() => setSearchParams({ category: tag })}
                >
                  <span>{tag}</span>
                  <span
                    className={`flex items-center justify-center border rounded-md w-8 text-xs font-semibold ${
                      tag === category
                        ? "bg-primary border-primary text-primary-foreground"
                        : ""
                    }`}
                  >
                    {count}
                  </span>
                </li>
              ))}
          </ul>
        </div>
        <div className="col-span-9">
          <h3 className="mt-0">Browse our APIs</h3>

          <div className="grid grid-cols-2 gap-4">
            {apis
              .filter((api) => !category || api.tags.includes(category))
              .map((api, i) => (
                <Link
                  to={{
                    pathname: `/${api.navigationId}`,
                    search: category ? `category=${category}` : "",
                  }}
                  className="no-underline hover:!text-foreground"
                  key={api.navigationId}
                >
                  <div
                    className="border h-full rounded p-4 flex flex-col gap-2 cursor-pointer hover:bg-border/20 font-normal"
                    key={i}
                  >
                    <span className="font-semibold">{api.title}</span>
                    <span className="text-sm whitespace-pre-wrap mb-6 line-clamp-2">
                      {api.description}
                    </span>
                    <span className="text-sm whitespace-pre-wrap">
                      <span className="font-medium">Region: </span>
                      {api.region.join(", ")}
                    </span>
                    <span className="text-sm whitespace-pre-wrap">
                      <span className="font-medium">Use for: </span>
                      {api.tags.join(", ")}
                    </span>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};
