export default [
  {
    type: "url",
    title: "Loans API",
    description:
      "Our Loan Origination Systems (LOS) are designed to be flexible, scalable, and tailored to your business needs, offering powerful AI-driven automation tools like Empower LOS and NOVA LOS.",
    input: "https://darkmatter-main-0d7c426.d2.zuplo.dev/loans.json",
    navigationId: "api-loans",
    tags: ["Lending", "Automation", "AI"],
    region: ["North America", "Europe", "APAC"],
  },
  {
    type: "url",
    title: "Insurance API",
    description:
      "Our AI-powered platform is designed to automate and enhance every stage of claims processing—from filing to settlement. By leveraging advanced workflows, we help insurance companies improve accuracy, reduce fraud, and speed up claims handling.",
    input: "https://darkmatter-main-0d7c426.d2.zuplo.dev/insurance.json",
    navigationId: "api-insurance",
    tags: ["Insurance", "Automation", "AI"],
    region: ["North America", "Europe"],
  },
  {
    type: "url",
    title: "Fraud Detection API",
    description:
      "Use our AI-driven fraud detection system to analyze patterns and identify high-risk transactions across financial services. Reduce losses and protect your business from fraudulent activities.",
    input: "https://darkmatter-main-0d7c426.d2.zuplo.dev/fraud-detection.json",
    navigationId: "api-fraud-detection",
    tags: ["Risk Management", "AI", "Fraud Detection"],
    region: ["North America", "Europe", "APAC", "LATAM"],
  },
  {
    type: "url",
    title: "Mortgage Automation API",
    description:
      "Automate and streamline the mortgage process from application to closing. Our mortgage automation platform reduces manual errors and accelerates time-to-close with integrated AI workflows.",
    input: "https://darkmatter-main-0d7c426.d2.zuplo.dev/mortgage.json",
    navigationId: "api-mortgage",
    tags: ["Lending", "Automation", "AI"],
    region: ["North America", "Europe"],
  },
  {
    type: "url",
    title: "Payment Processing API",
    description:
      "Efficiently process payments with our highly scalable and secure payment processing API. It supports multiple payment methods and ensures compliance with industry standards.",
    input: "https://darkmatter-main-0d7c426.d2.zuplo.dev/payments.json",
    navigationId: "api-payments",
    tags: ["Payments", "Automation", "Compliance"],
    region: ["North America", "Europe", "APAC", "LATAM"],
  },
  {
    type: "url",
    title: "KYC Verification API",
    description:
      "Enhance security and ensure compliance with our AI-driven KYC (Know Your Customer) verification API. Automatically validate identities and reduce risk in customer onboarding.",
    input: "https://darkmatter-main-0d7c426.d2.zuplo.dev/kyc.json",
    navigationId: "api-kyc-verification",
    tags: ["Compliance", "Risk Management", "AI"],
    region: ["North America", "Europe", "APAC"],
  },
  {
    type: "url",
    title: "Customer Onboarding API",
    description:
      "Seamlessly onboard new customers with our AI-powered customer onboarding API. It integrates identity verification, risk assessment, and compliance checks for smooth user experiences.",
    input: "https://darkmatter-main-0d7c426.d2.zuplo.dev/onboarding.json",
    navigationId: "api-onboarding",
    tags: ["Onboarding", "Compliance", "AI"],
    region: ["North America", "Europe", "APAC", "LATAM"],
  },
  {
    type: "url",
    title: "Risk Assessment API",
    description:
      "Evaluate creditworthiness and risk with our AI-driven risk assessment API. Get real-time analysis and predictive insights to make informed lending decisions quickly.",
    input: "https://darkmatter-main-0d7c426.d2.zuplo.dev/risk-assessment.json",
    navigationId: "api-risk-assessment",
    tags: ["Lending", "Risk Management", "AI"],
    region: ["North America", "Europe", "APAC"],
  },
  {
    type: "url",
    title: "Compliance API",
    description:
      "Ensure regulatory compliance with our Compliance API, which automates reporting and monitors adherence to changing industry regulations in real-time.",
    input: "https://darkmatter-main-0d7c426.d2.zuplo.dev/compliance.json",
    navigationId: "api-compliance",
    tags: ["Compliance", "Risk Management", "Automation"],
    region: ["North America", "Europe"],
  },
  {
    type: "url",
    title: "Credit Scoring API",
    description:
      "Leverage advanced AI algorithms to calculate accurate credit scores. Our credit scoring API evaluates multiple data sources for more reliable risk profiles.",
    input: "https://darkmatter-main-0d7c426.d2.zuplo.dev/credit-scoring.json",
    navigationId: "api-credit-scoring",
    tags: ["Lending", "Risk Management", "AI"],
    region: ["North America", "Europe", "APAC"],
  },
  {
    type: "url",
    title: "Document Management API",
    description:
      "Streamline document processing with our AI-powered document management API. Automate document classification, extraction, and storage for faster approvals and reduced manual work.",
    input:
      "https://darkmatter-main-0d7c426.d2.zuplo.dev/document-management.json",
    navigationId: "api-document-management",
    tags: ["Automation", "Compliance", "AI"],
    region: ["North America", "Europe", "APAC"],
  },
  {
    type: "url",
    title: "Underwriting API",
    description:
      "Optimize the underwriting process with AI-based automation. Our underwriting API analyzes risk factors and provides insights to make faster, more accurate underwriting decisions.",
    input: "https://darkmatter-main-0d7c426.d2.zuplo.dev/underwriting.json",
    navigationId: "api-underwriting",
    tags: ["Lending", "Risk Management", "AI"],
    region: ["North America", "Europe", "APAC"],
  },
];
